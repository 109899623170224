import React from "react"
import Layout from "../../components/orbit components/components/layout"
import AgeGate from "../../components/orbit components/components/agegate"

const AgeGatePage = () => {
  return (
    <Layout>
      <AgeGate />
    </Layout>
  )
}
export default AgeGatePage
